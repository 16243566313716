<template>
  <b-container class="page-container">
    <Breadcrumbs :currentpage="$t('setup-new-password')" />
    <h1 class="text-center">{{ $t('setup-new-password') }}</h1>
    <b-alert v-if="invalidLink || !code" show variant="warning">
      <font-awesome-icon icon="exclamation-circle" />{{
        $t('password-reset-link-expired-invalid')
      }}
      <router-link :to="{ name: 'forgotPassword' }">{{
        $t('new-password-lowercase')
      }}</router-link
      >.
    </b-alert>

    <p v-if="!invalidLink && code">{{ $t('fill-in-new-password-below') }}</p>

    <b-row v-if="!invalidLink && code">
      <b-col sm="12" class="mb-3"></b-col>
      <b-col cols="12">
        <hr />
        <password-helper-block />
      </b-col>

      <b-col sm="12" md="6" lg="4">
        <b-card>
          <b-form @submit.prevent="resetPasswordSubmit">
            <b-form-group
              id="input-group-1"
              :label="$t('new-password')"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.password"
                :state="passwordValidation"
                type="password"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              :label="$t('repeat-new-password')"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="form.confirmPassword"
                :state="passwordConfirmState && passwordValidation"
                type="password"
                required
              ></b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="12">
                <b-button
                  :disabled="!passwordConfirmState || !passwordValidation"
                  class="mb-2"
                  type="submit"
                  variant="primary"
                >
                  {{ $t('change-password') }}
                  <font-awesome-icon
                    v-if="requesting"
                    :icon="['fad', 'spinner']"
                    class="fa-spin"
                    far
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { setNewPassword } from '@/services/AuthorizationService'
import Breadcrumbs from '@/components/header/BreadCrumbs'
import PasswordHelperBlock from '@/components/global/PasswordHelperBlock.vue'
export default {
  components: {
    Breadcrumbs,
    PasswordHelperBlock
  },
  data() {
    return {
      form: {
        password: '',
        confirmPassword: ''
      },
      invalidLink: false,
      success: false,
      requesting: false,
      wrongPassword: false
    }
  },
  computed: {
    code: function() {
      return this.$route.query.code || false
    },
    passwordValidation() {
      let password = this.form.password
      if (password != '') {
        if (password.length < 8) {
          return false
        }

        if (!password.match(/[!@$%&\-+*()&^]/)) return false
        if (!password.match(/[0-9]/)) return false
        if (!password.match(/[a-z]/)) return false
        if (!password.match(/[A-Z]/)) return false
        if (!/^([0-9a-zA-Z!@$%&\-+*()&^]*)$/.exec(password)) return false

        return true
      }

      return null
    },

    passwordConfirmState() {
      if (this.form.password != '') {
        if (this.form.password == this.form.confirmPassword) {
          return true
        }
        return false
      }
      return null
    }
  },
  watch: {
    form: {
      handler() {
        if (this.form.password != this.confirmPassword) {
          this.wrongPassword = true
        } else {
          this.wrongPassword = false
        }
      },
      deep: true
    }
  },
  methods: {
    async resetPasswordSubmit() {
      this.requesting = true

      const result = await setNewPassword({
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        code: this.code
      })

      if (
        result.data != null &&
        result.data != 'FAILURE' &&
        result.data == 'SUCCESS'
      ) {
        this.success = true
        this.$router.push({ name: 'login', query: { passwordReset: true } })
      } else {
        this.invalidLink = true
      }

      this.requesting = false
    }
  }
}
</script>
<style scoped lang="scss">
a.btn {
  text-decoration: none;
}
</style>
